import { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import { values } from 'lodash';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { controlPanelActions } from '../../actions/control-panel.actions';
import { searchSecuritiesActions } from '../../actions/search.securities.actions';
import { securityDetailsActions } from '../../actions/security.details.actions';
import { companyDetailsActions } from '../../actions/company-details.actions';
import { inventoryActions } from '../../actions/inventory.actions';
import { constants } from '../../constants/constants';
import { roles } from '../../constants/roles';
import { routes } from '../../constants/routes';
import { SORTING_TYPE } from '../../constants/sort';
import IconSVG from '../../styles/svg-icons';
import { SubscriptionFeature } from '../../types/billing/SubscriptionFeature';
import { CompanySlim } from '../../types/company/CompanySlim';
import { BwicFilterType } from '../../types/filters/FilterState';
import { InventoryPosition } from '../../types/inventory/InventoryPosition';
import { InventoryPositionStatus } from '../../types/inventory/InventoryPositionStatus';
import { InventoryPositionWithLock } from '../../types/inventory/InventoryPositionWithLock';
import { InventorySecurityOrderByColumn, InventorySecuritySortOrder } from '../../types/inventory/InventorySecurityOrderByColumn';
import { ColumnConfigKey } from '../../types/page-config/column-customizer/ColumnConfigKey';
import { PageConfigType } from '../../types/page-config/PageConfigType';
import { AppState } from '../../types/state/AppState';
import { user } from '../../user/user';
import { formatUtils } from '../../utils/format.utils';
import { isRequestFailed, isRequesting, isRequestNone, isRequestSuccess } from '../../utils/request-state.utils';
import { moneyUtils } from '../../utils/money.utils';
import { createColumnListBuilder } from '../../utils/ColumnListBuilder';
import dateTimeUtils from '../../utils/dateTime.utils';
import { ShowFor } from '../access/ShowFor';
import { ActionBlocker } from '../access/ActionBlocker';
import { FeatureButton } from '../access/FeatureButton';
import { getAmrColumns } from '../amrPipeline/common-columns';
import { ControlPanelOptions } from '../bidding/common/ControlPanelOptions';
import { Table } from '../bidding/common/table';
import { ColumnBuilder } from '../bidding/common/table/columns/column-builder/ColumnBuilder';
import { IColumnDefinition } from '../bidding/common/table/types/ColumnDefinition';
import { EmptyPlaceholder } from '../common/EmptyPlaceholder';
import { OnHoverTooltip } from '../common/OnHoverTooltip';
import { Preloader } from '../common/Preloader';
import { Relative } from '../common/Relative';
import { TickerButton } from '../common/TickerButton';
import { BloombergLink } from '../common/BloombergLink';
import { IntexLink } from '../common/IntexLink';
import { BwicColumnCustomizer } from '../common/pageConfig/column-customizer/BwicColumnCustomizer';
import { usePageConfig } from '../common/pageConfig/usePageConfig';
import { SelectBrokerDealerPopup } from '../common/SelectBrokerDealerPopup';
import { ValitanaLink } from '../common/ValitanaLink';
import { CompanyDetailsButton } from '../company-details/CompanyDetailsButton';
import { CompanyDetailsPanel } from '../company-details/CompanyDetailsPanel';
import { SecuritiesSearchInput } from '../filters';
import { useManagersFilterData } from '../filters/dinamicFilterEffects/useManagersFilterData';
import { useTrusteeFilterData } from '../filters/dinamicFilterEffects/useTrusteeFilterData';
import { SecurityDetailsPanel } from '../security-details';
import { useBrokerDealersFilterData } from './dynamicFiltersFillers/useBrokerDealersFilterData';
import { InventoryListFilter } from './filter/InventoryListFilter';
import { InventoryStatusLabel } from './InventoryStatusLabel';
import { bwicLogActions } from '../../actions/bwic-log.actions';
import { TradedButton } from './TradedButton';
import { ViewBwicColumn } from './ViewBwicColumn';
import { ImDealLookupLink } from '../amrPipeline/common/ImDealLookupLink';
import { collapseStickRightColumn } from '../bidding/common/table/CollapseStickRightColumn';
import { BlockedFeatureContent, SubscribeLink } from '../access/BlockedFeatureText';
import { DealerInventoryAlertsPreferences } from '../profile/tabs/email-preferences/tabs/dealer-inventory/DealerInventoryAlertsPreferences';
import { InventorySummaryRow } from './InventorySummaryRow';
import { appConfig } from '../../app-config';
import { inventoryColumnLabels } from '../../constants/inventoryColumnsLabels';
import { useAppDispatch } from '../../effects/useAppDispatch';
import { inventoryEditActions } from '../../actions/inventory-edit.actions';
import { ViewDealDocumentsButton } from '../documents/deal-documents-popup/ViewDealDocumentButton';

export function InventoryList() {
    const pageConfigType = PageConfigType.DealerInventory;
    const history = useHistory();
    const dispatch = useAppDispatch();
    const sortingField = useRef(InventorySecurityOrderByColumn[InventorySecurityOrderByColumn.lastUpdateDate]);
    const sortDirection = useRef(InventorySecuritySortOrder[InventorySecuritySortOrder.desc]);
    const requestStatusExportInventory = useSelector((state: AppState) => state.inventory.requestStatusExportInventory);
    const isInventoryExist = useSelector((state: AppState) => state.inventory.isInventoryExist);
    const requestStateSearchInventory = useSelector((state: AppState) => state.entities.allInventory.requestState);
    const totalRecordNumber = useSelector((state: AppState) => state.entities.allInventory.totalRecordNumber);
    const currentPageNumber = useSelector((state: AppState) => state.entities.allInventory.currentPageNumber);
    const pageSize = useSelector((state: AppState) => state.entities.allInventory.pageSize);
    const inventoryList = useSelector((state: AppState) => state.entities.allInventory.items);
    const securityDetails = useSelector((state: AppState) => state.securityDetails.security);
    const bwicLogSecurity = useSelector((state: AppState) => state.bwicLog.security);
    const companyDetailsCurrentCompanyId = useSelector((state: AppState) => state.companyDetails.currentCompanyId);
    const requestStateInventoryExist = useSelector((state: AppState) => state.inventory.requestStateInventoryExist);
    const requestStateSetTradedStatus = useSelector((state: AppState) => state.inventoryEdit.requestStateSetTradedStatus);
    const activeInventoryId = useSelector((s: AppState) => Number(s.securityDetails.security?.id ?? s.bwicLog.security?.id ?? s.companyDetails.rowKey ?? 0));
    const { config, requestState: pageConfigRequestState, defaultConfigRequestState } = usePageConfig(pageConfigType, false, BwicFilterType.Inventory);
    const columnsConfig = user.hasFeatures(SubscriptionFeature.InventoryColumnCustomizer)
        ? config?.columns
        : undefined;

    const isPageConfigLoaded = isRequestSuccess(pageConfigRequestState, defaultConfigRequestState)
        || isRequestFailed(pageConfigRequestState, defaultConfigRequestState);

    const isEmptyInventoryList = isRequestSuccess(requestStateSearchInventory) && !inventoryList.length;
    const isScrollUploadEnabled = totalRecordNumber > (currentPageNumber * pageSize);

    const getOrderByColumn = () => InventorySecurityOrderByColumn[sortingField.current as keyof typeof InventorySecurityOrderByColumn];
    const getOrderByDirection = () => InventorySecuritySortOrder[sortDirection.current as keyof typeof InventorySecuritySortOrder];

    const [editInventoryPopupVisible, setEditInventoryPopupVisible] = useState(false);

    const isAdminOrDataEntry = user.hasRoles(roles.Administrator, roles.DataEntry);
    const hasIssuenceMonitorAccess = user.hasRoles(...roles.issuanceMonitorAccess());

    const brokerDealersRequestState = useBrokerDealersFilterData(BwicFilterType.Inventory, 'dealerIds', item => item.hasInventory);
    const managersRequestState = useManagersFilterData(BwicFilterType.Inventory);
    const trusteeRequestState = useTrusteeFilterData(BwicFilterType.Inventory);
    const adavancedFiltersBlocked = useSelector((s: AppState) => s.inventory.advancedFilterBlocked);

    const isDynamicFiltersDataLoaded = isRequestSuccess(brokerDealersRequestState) &&
        (isRequestSuccess(managersRequestState) || isRequestFailed(managersRequestState)) &&
        (isRequestSuccess(trusteeRequestState) || isRequestFailed(trusteeRequestState));

    useEffect(() => {
        if (companyDetailsCurrentCompanyId && !inventoryList.some(i => i.dealer.id === companyDetailsCurrentCompanyId)) {
            dispatch(companyDetailsActions.hide())
        }
    }, [inventoryList, companyDetailsCurrentCompanyId, dispatch]);

    useEffect(() => {
        if (securityDetails && !inventoryList.some(i => i.ticker === securityDetails.ticker)) {
            dispatch(securityDetailsActions.securityDetailsReset());
        }
    }, [securityDetails, inventoryList, dispatch]);

    useEffect(() => {
        if (bwicLogSecurity && !inventoryList.some(i => i.ticker === bwicLogSecurity.ticker)) {
            dispatch(bwicLogActions.bwicLogReset());
        }
    }, [bwicLogSecurity, inventoryList, dispatch]);

    useEffect(() => {
        return () => {
            dispatch(controlPanelActions.hide());
            dispatch(searchSecuritiesActions.reset());
            dispatch(inventoryActions.reset());
            dispatch(inventoryActions.resetEntities());
        }
    }, [dispatch]);

    useEffect(() => {
        if (isPageConfigLoaded && isRequestNone(requestStateSearchInventory) && isDynamicFiltersDataLoaded) {
            dispatch(inventoryActions.init(getOrderByColumn(), getOrderByDirection()));
        }
    }, [dispatch, requestStateSearchInventory, isDynamicFiltersDataLoaded, isPageConfigLoaded]);

    const renderEmptyList = () => (
        <EmptyPlaceholder
            title="No Current Inventory"
            text="Broker-dealer inventories will be shown here."
        >
            <ShowFor role={roles.Administrator}>
                <button className="btn btn-main" onClick={() => setEditInventoryPopupVisible(true)}>Add Inventory</button>
            </ShowFor>
            <ShowFor roles={roles.seller()}>
                <button className="btn btn-main" onClick={() => history.push(routes.allBWICs)}>View BWIC Monitor</button>
            </ShowFor>
        </EmptyPlaceholder>
    );

    const renderTable = () => {
        const renderTradedButton = (position: InventoryPositionWithLock) => {
            return (
                <TradedButton
                    position={position}
                    isTradedChangeInProgress={requestStateSetTradedStatus}
                    onTradedChange={(tradeDate) => dispatch(inventoryEditActions.setTradedStatus(position.id, position.lockModifiedDate, tradeDate))}
                />
            );
        };

        const { collateralType, closingDate, nonCallPeriodEnd, reinvestmentPeriodEnd, dealName, manager, outOfNC, outOfRI, reinvestmentPeriodEndYears, nonCallPeriodEndYears, ...restAmrColumns } = getAmrColumns();
        const amrColumns = values(restAmrColumns);

        const columnDefinitions: IColumnDefinition<InventoryPositionWithLock>[] = [
            {
                columnKey: 'status',
                renderColumnHeaderContent: () => '',
                renderColumnContent: entity => <InventoryStatusLabel status={entity.status} />,
                className: 'data-list-cell-xxs',
                stickLeft: true
            }, {
                columnKey: 'lastUpdateDate',
                configKey: ColumnConfigKey.LastUpdateDate,
                renderColumnHeaderContent: () => 'Last Updated',
                renderColumnContent: entity =>
                    <OnHoverTooltip overlay={dateTimeUtils.utcToLocalString(entity.lastUpdateDate, constants.dateTimeFormat)}>
                        {moment.utc(entity.lastUpdateDate).format(constants.dateFormat)}
                    </OnHoverTooltip>,
                className: 'data-list-cell-sm',
                sortingField: 'lastUpdateDate',
                sortingType: SORTING_TYPE.date,
                stickLeft: true
            }, {
                columnKey: 'dealer',
                configKey: ColumnConfigKey.Dealer,
                renderColumnHeaderContent: () => 'Dealer',
                renderColumnContent: entity => (
                    <CompanyDetailsButton
                        title={entity.dealer.code}
                        isBrokerDealer={true}
                        company={entity.dealer}
                        rowKey={entity.id}
                        onClick={() => dispatch(controlPanelActions.hide())}
                    />
                ),
                sortingField: 'dealerId',
                sortingType: SORTING_TYPE.number,
                stickLeft: true
            }, {
                columnKey: 'ticker',
                configKey: ColumnConfigKey.Ticker,
                renderColumnHeaderContent: () => 'Ticker',
                renderColumnContent: entity => <TickerButton security={entity} fetchAgencyRatings={true} />,
                className: 'data-list-cell-lg',
                sortingField: 'ticker',
                sortingType: SORTING_TYPE.string,
                stickLeft: true
            },{
                columnKey: 'deal-documents',
                configKey: ColumnConfigKey.DealDocuments,
                renderColumnHeaderContent: () => 'Docs',
                renderColumnContent: entity => entity.documentsCount
                    ? <ViewDealDocumentsButton className="text-regular" security={entity} />
                    : constants.emptyPlaceholder,
                className: '',
            }, { ...manager!, configKey: ColumnConfigKey.CollateralManager },
            ({ ...ViewBwicColumn, stickLeft: false } as IColumnDefinition<InventoryPositionWithLock>),
            {
                columnKey: 'currency',
                configKey: ColumnConfigKey.Currency,
                renderColumnHeaderContent: () => 'Ccy',
                renderColumnContent: entity => entity.currency,
                className: 'data-list-cell-xs',
                sortingField: 'currency',
                sortingType: SORTING_TYPE.string
            }, {
                columnKey: 'rating',
                configKey: ColumnConfigKey.Rating,
                renderColumnHeaderContent: () => 'Rtg',
                renderColumnContent: entity => entity.rating,
                className: 'data-list-cell-xs',
                sortingField: 'rating',
                sortingType: SORTING_TYPE.rating
            }, {
                columnKey: 'bidSize',
                configKey: ColumnConfigKey.BidSize,
                renderColumnHeaderContent: () => 'Bid Size, MM',
                renderColumnContent: entity => entity.bidSize ? moneyUtils.amountToMM(entity.bidSize) : constants.emptyPlaceholder,
                className: 'data-list-cell-sm text-right',
                sortingField: 'bidSize',
                sortingType: SORTING_TYPE.number
            }, {
                columnKey: 'size',
                configKey: ColumnConfigKey.Size,
                renderColumnHeaderContent: () => 'Offer Size, MM',
                renderColumnContent: entity => moneyUtils.amountToMM(entity.size),
                className: 'data-list-cell-sm text-right',
                sortingField: 'size',
                sortingType: SORTING_TYPE.number
            },
            { ...collateralType, configKey: ColumnConfigKey.CollateralType },
            {
                columnKey: 'bid',
                configKey: ColumnConfigKey.Bid,
                renderColumnHeaderContent: () => 'Bid, %',
                renderColumnContent: entity => entity.bid ? entity.bid.toFixed(3) : constants.emptyPlaceholder,
                className: 'text-right',
                sortingField: 'bid',
                sortingType: SORTING_TYPE.number
            }, {
                columnKey: 'offer',
                configKey: ColumnConfigKey.Offer,
                renderColumnHeaderContent: () => 'Offer, %',
                renderColumnContent: entity => entity.offer.toFixed(3),
                className: 'text-right',
                sortingField: 'offer',
                sortingType: SORTING_TYPE.number
            }, {
                columnKey: 'creditEnhancement',
                configKey: ColumnConfigKey.CreditEnhancement,
                renderColumnHeaderContent: () => 'C/E, %',
                renderColumnContent: entity => entity.creditEnhancement ? formatUtils.formatDecimal(entity.creditEnhancement) : constants.emptyPlaceholder,
                className: 'text-right',
                sortingField: 'creditEnhancement',
                sortingType: SORTING_TYPE.number
            }, {
                columnKey: 'bidDmBps',
                configKey: ColumnConfigKey.BidDmBps,
                renderColumnHeaderContent: () => 'Bid DM, bps',
                renderColumnContent: entity => entity.bidDmBps || constants.emptyPlaceholder,
                className: 'data-list-cell-sm text-right',
                sortingField: 'bidDmBps',
                sortingType: SORTING_TYPE.number
            }, {
                columnKey: 'offerDmBps',
                configKey: ColumnConfigKey.OfferDmBps,
                renderColumnHeaderContent: () => 'Offer DM, bps',
                renderColumnContent: entity => entity.offerDmBps || constants.emptyPlaceholder,
                className: 'data-list-cell-sm text-right',
                sortingField: 'offerDmBps',
                sortingType: SORTING_TYPE.number
            }, {
                columnKey: 'offerYield',
                configKey: ColumnConfigKey.OfferYield,
                renderColumnHeaderContent: () => 'Yld, %',
                renderColumnContent: entity => entity.offerYield ? entity.offerYield.toFixed(2) : constants.emptyPlaceholder,
                className: 'text-right',
                sortingField: 'offerYield',
                sortingType: SORTING_TYPE.number
            }, {
                columnKey: 'coupon',
                configKey: ColumnConfigKey.Coupon,
                renderColumnHeaderContent: () => 'Coupon',
                renderColumnContent: entity => formatUtils.formatInventoryCoupon(entity, constants.emptyPlaceholder),
                className: 'data-list-cell-lg'
            }, {
                columnKey: 'walYears',
                configKey: ColumnConfigKey.WalYears,
                renderColumnHeaderContent: () => 'WAL',
                renderColumnContent: entity => entity.walYears ? entity.walYears.toFixed(2) : constants.emptyPlaceholder,
                className: 'text-right',
                sortingField: 'walYears',
                sortingType: SORTING_TYPE.number
            }, {
                columnKey: 'mvoc',
                configKey: ColumnConfigKey.Mvoc,
                renderColumnHeaderContent: () => 'MVOC, %',
                renderColumnContent: entity => entity.mvoc ? entity.mvoc.toFixed(2) : constants.emptyPlaceholder,
                className: 'text-right',
                sortingField: 'mvoc',
                sortingType: SORTING_TYPE.number
            },
            { ...dealName, configKey: ColumnConfigKey.DealName },
            { ...closingDate, configKey: ColumnConfigKey.ClosingDate },
            nonCallPeriodEnd,
            nonCallPeriodEndYears,
            outOfNC,
            reinvestmentPeriodEnd,
            reinvestmentPeriodEndYears,
            outOfRI,
            ...amrColumns,
            {
                columnKey: 'description',
                configKey: ColumnConfigKey.Description,
                renderColumnHeaderContent: () => 'Note',
                renderColumnContent: entity =>
                    <OnHoverTooltip overlay={entity.description} wrapperClass="note-text">
                        <div className="text-ellipsis">{entity.description || constants.emptyPlaceholder}</div>
                    </OnHoverTooltip>,
                className: 'data-list-cell-note data-list-cell-md'
            }, collapseStickRightColumn, {
                columnKey: 'traded',
                renderColumnHeaderContent: () => '',
                renderColumnContent: renderTradedButton,
                className: 'padding-r-0 text-right',
                stickRight: true
            }, {
                columnKey: 'imDealLookupLink',
                renderColumnHeaderContent: () => '',
                renderColumnContent: security =>
                    <>
                        {
                            !!security.dealReferenceName &&
                            !!security.dealLegalName &&
                            <ImDealLookupLink
                                dealReferenceName={security.dealReferenceName}
                                dealLegalName={security.dealLegalName}
                            />
                        }
                    </>,
                className: 'data-list-cell-xxs padding-r-0 text-right lookup',
                stickRight: true
            }, {
                columnKey: 'externalLinks',
                renderColumnHeaderContent: () => 'Actions',
                renderColumnContent: security =>
                    <>
                        <IntexLink positionId={security.isinCusip} />
                        <BloombergLink identifier={security.isinCusip} />
                        <ValitanaLink identifier={security.isinCusip} />
                    </>,
                className: 'cell-external-links padding-r-0 text-right',
                stickRight: true
            }];

        let columnList =
            columnDefinitions
                .filter(c => isAdminOrDataEntry || c.columnKey !== 'traded')
                .filter(c => hasIssuenceMonitorAccess || c.columnKey !== 'imDealLookupLink')


        const columns = createColumnListBuilder<InventoryPositionWithLock>(columnsConfig)
            .add(...columnList)
            .result();

        return (
            <Table
                dataItems={inventoryList}
                columns={columns.map(c => new ColumnBuilder(c))}
                onNextPageRequest={handleNextPage}
                infiniteScrollEnabled={isScrollUploadEnabled}
                createSecurityCustomClassName={createSecurityCustomClassName}
                onSort={(field: string, direction: string) => {
                    sortingField.current = field;
                    sortDirection.current = direction;
                    handleFetchList();
                }}
                defaultSortBy={sortingField.current}
                defaultSortByDirection={sortDirection.current}
                sticky={true}
            />
        )
    };

    const createSecurityCustomClassName = (inventory: InventoryPosition) => {
        return classNames({
            active: inventory.id === activeInventoryId,
            'sold-status': inventory.status === InventoryPositionStatus.traded
        })
    };

    const handleNextPage = () => {
        if (!isRequesting(requestStateSearchInventory)) {
            dispatch(inventoryActions.loadNextPage());
            dispatch(inventoryActions.loadSellerInventory(getOrderByColumn(), getOrderByDirection(), false));
        }
    };

    const renderContent = () => {
        if (adavancedFiltersBlocked) {
            return <BlockedFeatureContent className="empty-placeholder" inline text={<><SubscribeLink /> to see Inventory details</>} />
        }
        if (!isInventoryExist && isRequestSuccess(requestStateInventoryExist)) {
            return renderEmptyList();
        }

        if (isEmptyInventoryList) {
            return <EmptyPlaceholder />
        }

        return renderTable();
    };

    const handleFetchList = () => {
        dispatch(inventoryActions.resetEntities());
        dispatch(inventoryActions.loadSellerInventory(getOrderByColumn(), getOrderByDirection(), true));
        dispatch(securityDetailsActions.securityDetailsReset());
    };

    const handleApplyFilter = () => {
        dispatch(inventoryActions.advancedFilterBlocked(false));
        handleFetchList();
    }

    const handleSearch = () => {
        dispatch(inventoryActions.resetEntities());
        dispatch(inventoryActions.loadSellerInventory(getOrderByColumn(), getOrderByDirection(), true, false));
        dispatch(securityDetailsActions.securityDetailsReset());
    }

    return (
        <div className="container my-inventory">
            <div className="sub-header sub-header-pipeline">
                <div className="sub-header-row type01 flex-row">
                    <h1>Dealer Inventory</h1>
                    <Relative>
                        <SecuritiesSearchInput
                            disabled={!isInventoryExist || isRequesting(requestStateSearchInventory)}
                            onSearch={handleSearch}
                            requiredFeature={SubscriptionFeature.InventorySecuritySearch}
                        />
                    </Relative>
                    <div className="flex-item-right controls">
                        <DealerInventoryAlertsPreferences />
                        <ShowFor roles={[...roles.seller(), roles.Viewer]}>
                            <Preloader small={true} fullScreen={false} inProgress={requestStatusExportInventory} text="Exporting…">
                                <ActionBlocker feature={SubscriptionFeature.ExportInventory}>
                                    <FeatureButton
                                        disabled={isEmptyInventoryList || isRequesting(requestStateSearchInventory)}
                                        onClick={() => dispatch(inventoryActions.exportInventoryRequest(sortingField.current, sortDirection.current))}
                                        className="btn btn-link flex-item-right"
                                    >
                                        <IconSVG name="export" width={16} height={16} /> Export
                                    </FeatureButton>
                                </ActionBlocker>
                            </Preloader>
                        </ShowFor>
                        <ShowFor roles={[roles.Administrator, roles.DataEntry]}>
                            <button
                                className="btn-link btn-link-edit"
                                disabled={!isInventoryExist || isRequesting(requestStateSearchInventory)}
                                onClick={() => setEditInventoryPopupVisible(true)}
                            >
                                <IconSVG name="edit-pencil" width={16} height={16} />
                                Edit List
                            </button>
                            {
                                editInventoryPopupVisible &&
                                <SelectBrokerDealerPopup
                                    onSelected={(company: CompanySlim) => history.push(routes.adminInventoryEditUrl(company.id))}
                                    onCancel={() => setEditInventoryPopupVisible(false)}
                                />
                            }
                        </ShowFor>
                    </div>
                </div>
                <div className="sub-header-row type02 flex-row align-items-flex-start row-align-filters">
                    <InventoryListFilter handleApplyFilter={handleApplyFilter} />
                    <BwicColumnCustomizer
                        columnLabels={inventoryColumnLabels}
                        tabs={{ 'All': PageConfigType.DealerInventory }}
                        disabled={!isPageConfigLoaded || !isInventoryExist || isRequesting(requestStateSearchInventory)}
                        defaultTab={pageConfigType}
                        requiredFeature={SubscriptionFeature.InventoryColumnCustomizer} />
                </div>
            </div>
            {appConfig.isSummaryRowHidden ? null : <InventorySummaryRow />}
            <div className="inventory-cnt flex-row flex-row-full-size position-relative">
                <Preloader inProgress={(isRequesting(requestStateSearchInventory) && !inventoryList.length) || !isDynamicFiltersDataLoaded || !isPageConfigLoaded}>
                    {renderContent()}
                </Preloader>
                {!isRequesting(requestStateSearchInventory) &&
                    <SecurityDetailsPanel
                        optionsExclude={[ControlPanelOptions.viewInventory]}
                    />}
                <CompanyDetailsPanel />
            </div>
        </div>
    );
}
